angular
    .module('annexaApp')
    .component('annexaBoxSessionPaginated', {
        templateUrl: './components/sec/annexa-box-session-paginated/annexa-box-session-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout','SecFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, SecFactory) {
            var vm = this;
            vm.langColumn = Language.getActiveColumn();
            
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
	                vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.box.permissions, 'view_sessions', 'view_sessions', vm.box.isEdit);
	                vm.secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec.proposals'").toArray();
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.sec.literals.sessions';
	                }
	        	}
            }
        }],
        bindings: {
            box: '='
        }
    })
