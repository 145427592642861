angular
    .module('annexaApp')
    .component('annexaBoxOrgans',{
        templateUrl: './components/sec/annexa-box-organs/annexa-box-organs.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope) {
            var vm = this;
            
            this.$onInit = function () {
               
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.sec.literals.organs';
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            canEdit: '='
        }
    })
    .component('annexaBoxOrgansRender', {
        templateUrl: './components/sec/annexa-box-organs/annexa-box-organs-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory', function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.agreementOrgan = {};
            vm.allOpinionOrgans = [];
            vm.allAgreementOrgans = [];
            vm.isActiveCouncillor = false;
			vm.councillorProposalType = undefined;
			
            vm.addOpinionOrgan = function(item) {
            	var options = [];
            	if(vm.isActiveCouncillor){
            		if(vm.config && vm.config.councillorId && vm.config.proposal && vm.config.proposal.proposalType  && vm.config.proposal.proposalType.id){
    					var councillor = $linq(GlobalDataFactory.councillors).firstOrDefault(undefined, "x => x.id == "+vm.config.councillorId);
    					if(councillor && councillor.councillorProposalTypes){
    						vm.councillorProposalType = $linq(councillor.councillorProposalTypes).firstOrDefault(undefined, "x => x.proposalType && x.proposalType.id == " + vm.config.proposal.proposalType.id);
    						if(vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
    							var organIds = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).where("x => x.organ && x.organ.id").select("x => x.organ.id").toArray(); 
    							vm.allOpinionOrgans = $linq(GlobalDataFactory.organs).where(function(x){
    								if(x && x.resolutionType == 'OPINION' && x.active){
    										if(_.contains(organIds, x.id)){
    										return true;
    									}		
    								}
    								return false;
    							}).toArray();
    						}else{
    							vm.allOpinionOrgans.length = 0;
    						}
    					}
    				}else{
    					vm.allOpinionOrgans.length = 0;
    				}
            	}
            	_.forEach(vm.allOpinionOrgans, function(proposalOrgan){
            		var organs = $linq(vm.content).where("x => x.organ && x.organ.id == "+proposalOrgan.id).toArray();
            		if(organs && organs.length > 0){
            			_.forEach(organs, function(organ){
            				if(organ.session && organ.session.executedOrder && $linq(options).count("x => x.id == "+organ.organ.id) == 0){
            					var orders = $linq(organ.session.executedOrder).where("x => x.proposal && x.proposal.id == "+organ.proposal.id).toArray();
            					if(orders){
            						var allRefused = true;
            						_.forEach(orders, function(order){
            							if(order.voteState != 'REFUSED' && order.voteState != 'POSTPONED'){
            								allRefused = false;
            							}
            						});
            						if(alllRefused == true){
            							options.push(proposalOrgan);
            						}
            					}
            				}else if(vm.isActiveCouncillor && item && item.organ && item.organ.id && $linq(options).count("x => x.id == "+item.organ.id) == 0){
								options.push(proposalOrgan);
							}
            			})
            		}else{
            			options.push(proposalOrgan);
            		}
            	});
            	if(options.length > 0){
					var newModal = {
	                    title: 'global.sec.literals.addOpinionOrgan',
	                    size: '',
	                    fieldsDefinition: [
	                    	{ type: 'field', id: 'organ', fieldType: 'select', data: options, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.organ',defaultValue:((item && item.organ && item.organ.id)?item.organ.id:undefined),
								onSelected: function(item) {
									$rootScope.$broadcast('AddOpinionOrganSelected', {item: item});
			                    }
							},
							{ type: 'field', id: 'delegationType', fieldType: 'select', data: [], colClass: 'col-sm-12', required: true, label: 'global.commonAdmin.literals.councillorDelegationType',defaultValue:((item && item.delegationType && item.delegationType.id)?item.delegationType.id:undefined),
                                controller: ['$scope', function($scope) {
									$scope.to.options.length = 0;
									if($scope.model && $scope.model.organ && vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
										var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+$scope.model.organ); 
										if(organ != null && organ.delegated && organ.councillorProposalTypeDelegationOrganDelegationTypes && organ.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
											_.forEach(organ.councillorProposalTypeDelegationOrganDelegationTypes, function(ocptdodt){
												$scope.to.options.push(ocptdodt.councillorProposalTypeDelegationType);
											});
										}else{
											$scope.model.delegationType = undefined;
										}	
									}else{
										$scope.model.delegationType = undefined;
									}

                                    $scope.$on('AddOpinionOrganSelected', function (event, args) {
										$scope.to.options.length = 0;
										if(args && args.item && args.item.id && vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
											var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+$scope.model.organ); 
											if(organ != null && organ.delegated && organ.councillorProposalTypeDelegationOrganDelegationTypes && organ.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
												_.forEach(organ.councillorProposalTypeDelegationOrganDelegationTypes, function(ocptdodt){
													$scope.to.options.push(ocptdodt.councillorProposalTypeDelegationType);
												});
												if(!$scope.model.delegationType || ($scope.model.delegationType && $linq($scope.to.options).firstOrDefault(undefined,"x => x.id == "+$scope.model.delegationType))){
													$scope.model.delegationType =  $scope.to.options[0].id;
												}
											}	
										}
                                    });
								}],
								hideExpression: function($viewValue, $modelValue, scope) {
                                    if(scope.model && scope.model.organ){
										if(vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0) {
											var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+scope.model.organ); 
											if(organ != null && organ.delegated){
	                                        	return false;
											}else{
		                                    	if(scope.model){
													scope.model.delegationType = undefined;
												}
												return true;
											}
										}else{
	                                    	if(scope.model){
												scope.model.delegationType = undefined;
											}
											return true;
										}
                                    } else {
                                    	if(scope.model){
											scope.model.delegationType = undefined;
										}
                                        return true;
                                    }
                                }

							}
	                    ],
	                    alerts: [],
	                    submitModal: function() {
	                    	this.form.$setSubmitted();
	                        if(this.form.$valid) {
	                        	if(vm.isEdit){
	                          		if(!item || !item.id){
										$rootScope.$broadcast('annexaBoxOrgansAddOpinionOrgan', { organ: this.model.organ, origin: vm.origin, modal: newModal, delegationType:this.model.delegationType});	
									}else{
										item.organ = {id:this.model.organ};
										item.delegationType = ((this.model.delegationType)?{id:this.model.delegationType}:undefined);
										$rootScope.$broadcast('annexaBoxOrgansModifyOpinionOrgan', { organ: item, origin: vm.origin, modal: newModal});
									}
	                        	}
	                        }
	                    },
	                    hideSubmit: false,
	                    closeLabel: 'global.literals.close'
	                }
	
	                AnnexaFormlyFactory.showAnnexaFormModal('modalAddOpinionOrgan', newModal);
            	}else{
            		DialogsFactory.notify('global.sec.literals.noOpinionOrganToAdd');
            	}
            }
            vm.selectOpinionOrgan = function(organ, index) {
            	if(vm.isEdit) {
                    $rootScope.$broadcast('annexaBoxOrgansModifyOpinionOrgan', { organ: organ, index:index, origin: vm.origin });
                }
            }
			vm.seeSession = function(organ) {
	                if(organ && organ.session && organ.session.id){
	                	window.open($state.href('annexa.sec.sessions.view', { session: organ.session.id }), '_blank');
	                }else if(vm.agreementOrgan && vm.agreementOrgan.session && vm.agreementOrgan.session.id){
	                	window.open($state.href('annexa.sec.sessions.view', { session: vm.agreementOrgan.session.id }), '_blank');
	                }
			}
			vm.selectAgreementOrgan = function() {
				if(vm.isEdit) {
                    $rootScope.$broadcast('annexaBoxOrgansModifyAgreementOrgan', { organ: vm.agreementOrgan, origin: vm.origin});
                }
			}
            
            vm.delete = function(organ, index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteOpinionOrganBox')
                    .then(function (data) {
                        var removedIds = [];
                        removedIds.push(organ.id);
                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxOrgansDelete', { removedIds: removedIds, index:index, origin: vm.origin });
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
            
			vm.addAgreementOrganModal = function(){
				if(vm.isActiveCouncillor && vm.config && vm.config.councillorId && vm.config.proposal && vm.config.proposal.proposalType  && vm.config.proposal.proposalType.id){
					var councillor = $linq(GlobalDataFactory.councillors).firstOrDefault(undefined, "x => x.id == "+vm.config.councillorId);
					if(councillor && councillor.councillorProposalTypes){
						vm.councillorProposalType = $linq(councillor.councillorProposalTypes).firstOrDefault(undefined, "x => x.proposalType && x.proposalType.id == " + vm.config.proposal.proposalType.id);
						if(vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
							var organIds = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).where("x => x.organ && x.organ.id").select("x => x.organ.id").toArray(); 
	    			        vm.allAgreementOrgans = $linq(GlobalDataFactory.organs).where(function(x){
								if(x && x.resolutionType == 'AGREEMENT' && x.active){
									if(_.contains(organIds, x.id)){
										return true;
									}		
								}
								return false;
							}).toArray();
						}else{
	            			vm.allAgreementOrgans.length = 0;
						}
					}
				}else{
	            	vm.allAgreementOrgans.length = 0;
				}
            	if(vm.allAgreementOrgans.length > 0){
	                var newModal = {
	                    title: 'global.sec.literals.addAgreementOrgan',
	                    size: '',
	                    fieldsDefinition: [
	                    	{ type: 'field', id: 'organ', fieldType: 'select', data: vm.allAgreementOrgans, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.organ',defaultValue:((vm.agreementOrgan && vm.agreementOrgan.organ && vm.agreementOrgan.organ.id)?vm.agreementOrgan.organ.id:undefined),
								onSelected: function(item) {
									$rootScope.$broadcast('AddAgreementOrganSelected', {item: item});
			                    }
							},
							{ type: 'field', id: 'delegationType', fieldType: 'select', data: [], colClass: 'col-sm-12', required: true, label: 'global.commonAdmin.literals.councillorDelegationType',defaultValue:((vm.agreementOrgan && vm.agreementOrgan.delegationType && vm.agreementOrgan.delegationType.id)?vm.agreementOrgan.delegationType.id:undefined),
                                controller: ['$scope', function($scope) {
									$scope.to.options.length = 0;
									if($scope.model && $scope.model.organ && vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
										var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+$scope.model.organ); 
										if(organ != null && organ.delegated && organ.councillorProposalTypeDelegationOrganDelegationTypes && organ.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
											_.forEach(organ.councillorProposalTypeDelegationOrganDelegationTypes, function(ocptdodt){
												$scope.to.options.push(ocptdodt.councillorProposalTypeDelegationType);
											});
										}else{
											$scope.model.delegationType = undefined;
										}	
									}else{
										$scope.model.delegationType = undefined;
									}

                                    $scope.$on('AddAgreementOrganSelected', function (event, args) {
										$scope.to.options.length = 0;
										if(args && args.item && args.item.id && vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0){
											var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+$scope.model.organ); 
											if(organ != null && organ.delegated && organ.councillorProposalTypeDelegationOrganDelegationTypes && organ.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
												_.forEach(organ.councillorProposalTypeDelegationOrganDelegationTypes, function(ocptdodt){
													$scope.to.options.push(ocptdodt.councillorProposalTypeDelegationType);
												});
												if(!$scope.model.delegationType || ($scope.model.delegationType && $linq($scope.to.options).firstOrDefault(undefined,"x => x.id == "+$scope.model.delegationType))){
													$scope.model.delegationType =  $scope.to.options[0].id;
												}
											}	
										}
                                    });
								}],
								hideExpression: function($viewValue, $modelValue, scope) {
                                    if(scope.model && scope.model.organ){
										if(vm.councillorProposalType && vm.councillorProposalType.councillorProposalTypeDelegationOrgans && vm.councillorProposalType.councillorProposalTypeDelegationOrgans.length > 0) {
											var organ = $linq(vm.councillorProposalType.councillorProposalTypeDelegationOrgans).firstOrDefault(undefined, "x => x.organ && x.organ.id == "+scope.model.organ); 
											if(organ != null && organ.delegated){
	                                        	return false;
											}else{
		                                    	if(scope.model){
													scope.model.delegationType = undefined;
												}
		                                    	return true;
											}
										}else{
	                                    	if(scope.model){
												scope.model.delegationType = undefined;
											}
	                                    	return true;
										}
                                    } else {
                                    	if(scope.model){
											scope.model.delegationType = undefined;
										}
                                    	return true;
                                    }
                                }

							}
	                    ],
	                    alerts: [],
	                    submitModal: function() {
	                    	this.form.$setSubmitted();
	                        if(this.form.$valid) {
								if(vm.isEdit) {
									vm.agreementOrgan.organ = {id:this.model.organ};
									vm.agreementOrgan.delegationType = ((this.model.delegationType)?{id:this.model.delegationType}:undefined);
				                    $rootScope.$broadcast('annexaBoxOrgansModifyAgreementOrgan', { organ: vm.agreementOrgan, origin: vm.origin, modal: newModal, modifyLocalOrgan:vm.modifyLocalAgreementOrgan});
				                }	                        	
	                        }
	                    },
	                    hideSubmit: false,
	                    closeLabel: 'global.literals.close'
	                }
	                AnnexaFormlyFactory.showAnnexaFormModal('modalAddOpinionOrgan', newModal);
            	}else{
            		DialogsFactory.notify('global.sec.literals.noAgreementOrganToAdd');
            	}
			};
			
			vm.modifyLocalAgreementOrgan = function(organ){
				if(organ){
					vm.agreementOrgan = organ;
				}else{
					vm.agreementOrgan = {};
				}
			}
            vm.getIconClassStatus = function (status) {
                if(status){
                    if(status == 'PENDING' || status == 'CONVENED' || status == 'STARTED'){
                        return 'blue';
                    }else if(status == 'EXECUTED' || status == 'FINISHED'){
                        return 'success';
                    }else if(status == 'CANCELED'){
                        return 'danger';
                    } else {
                        return'text-grey';
                    }
                }else{
                    return 'text-grey';
                }
            }
            
            vm.getNameStatus = function (status) {
                if(status){
                    return $filter('translate')('global.sec.literals.' + status);
                }else{
                    return '';
                }                
            }
            
            this.$onInit = function () {
				if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
					vm.isActiveCouncillor = true;
					vm.allOpinionOrgans = [];
		    		vm.allAgreementOrgans = [];
                }else{
					vm.isActiveCouncillor = false;
					vm.allOpinionOrgans = ((GlobalDataFactory.organs && $linq(GlobalDataFactory.organs).count("x => x.resolutionType == 'OPINION' && x.active") > 0)? $linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'OPINION' && x.active").toArray():[]);
		            vm.allAgreementOrgans = ((GlobalDataFactory.organs && $linq(GlobalDataFactory.organs).count("x => x.resolutionType == 'AGREEMENT' && x.active") > 0)? $linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'AGREEMENT' && x.active").toArray():[]);
				}
            	if(vm.content){
                	_.forEach(vm.content, function(organ){
                		if(organ.session && organ.session.id){
                    		organ.sessionInformation = organ.organ[vm.languageColumn]+"  -  "+$filter('translate')('global.sec.literals.session')+" "+organ.session.sessionType[vm.languageColumn]+" "+$filter('translate')('global.profile.from')+" "+$filter('date')(new Date(organ.session.sessionDateFirstConvene), 'dd/MM/yyyy HH:mm');
                    	}
                    });
                	var agreementOrgans = $linq(vm.content).where("x => x.organ.resolutionType == 'AGREEMENT'").toArray();
                	if(agreementOrgans && agreementOrgans.length > 0){
                		vm.agreementOrgan = agreementOrgans[0];
                	}
                }
            }

        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            origin: '@',
            canEdit: '=',
			config: '=?'
        }
    })