angular
    .module('annexaApp')
    .component('annexaBoxObjectSessionNotifications',{
        templateUrl: './components/sec/annexa-box-object-session-notifications/annexa-box-object-session-notifications.html',
        require: {
            sessionComponent: '^^annexaBoxObjectSession'
        },
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService','NotificationFactory', 'globalModals', 'CustomFieldFactory', 
        	function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, NotificationFactory, globalModals, CustomFieldFactory) {
            	var vm = this;

                vm.languageColumn = Language.getActiveColumn();
                vm.notificationStatus = angular.copy(NotificationFactory.notificationGlobalStatus);
				vm.dtWithoutNotifications = [];
				
				vm.showDtWithoutNotifications = function(){
					var showDTWN = false;
					if(vm.dtWithoutNotifications && vm.dtWithoutNotifications.length > 0){
						_.forEach(vm.dtWithoutNotifications, function(dtwn){
							if(dtwn.parent){
								if(dtwn.sons && dtwn.sons.length > 0){
									showDTWN = true;	
								}
							}else{
								showDTWN = true;
							}
						});
					}
					return showDTWN;
				} 
				
				var renderDossierTransaction = function(data, type, full, meta) {
                    var content = '';
					if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0 && data && data.id){
						var executedOrder = $linq(vm.sessionComponent.session.executedOrder).firstOrDefault(undefined, "x => x.proposal && x.proposal.notificationTransaction && x.proposal.notificationTransaction.id == "+data.id);
						if(executedOrder && executedOrder.proposal && executedOrder.proposal.notificationTransaction){
							var nt = executedOrder.proposal.notificationTransaction;
							if(nt.dossier && nt.dossier.dossierNumber){
								content += '<span>' + nt.dossier.dossierNumber + '</span><br/><span>'+nt[vm.languageColumn]+'</span>';
							}
						}
					}
                    return content;
                }

				var renderProposal = function(data, type, full, meta) {
                    var content = '';
					if(vm.sessionComponent && vm.sessionComponent.session && vm.sessionComponent.session.executedOrder && vm.sessionComponent.session.executedOrder.length > 0 && data && data.id){
						var executedOrder = $linq(vm.sessionComponent.session.executedOrder).firstOrDefault(undefined, "x => x.proposal && x.proposal.notificationTransaction && x.proposal.notificationTransaction.id == "+data.id);
						if(executedOrder && executedOrder.proposal){
							var prop = executedOrder.proposal;
							if(prop && prop.proposalNumber){
								content += '<span>' + prop.proposalNumber;
							}
						}
					}
                    return content;
                }
                
				var renderNotificationLastStatus = function(data, type, full, meta) {
					var orderedByDateStatuses = $linq(data).orderBy("x => x.createdDate").toArray();
		            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ? orderedByDateStatuses[orderedByDateStatuses.length - 1].status[vm.languageColumn] : '';
                }

                vm.showNotificationModal = function (notificationId) {
                    RestService.findOne('Notification', notificationId).then(function (data) {
                        var decodedData = JSOG.decode(data.data);
                    	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                        AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                    }).catch(function (error) {
                    	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                    });
                    
                };
               
                var getFilterCall = function() {
                    var filterCall = {};

                    return filterCall;
                }

                var getFilterCallAux = function () {
                    return { 
                    	session: vm.sessionComponent.session.id,
                    	filterOrigin: 'session',
                    };
                }

				var notificationGlobalStatusRender = function(data, type, full, meta) {
		            var content = '';
		
		            if(vm.notificationStatus && vm.notificationStatus.length > 0) {
		                var state = $linq(vm.notificationStatus).singleOrDefault(undefined, "x => x.id == '" + data + "'");
		                if(state) {
		                    content += '<div class="text-center">';
		                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
		                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
		                    content += '    </span>';
		                    content += '</div>';
		                }
		            }
		
		            return content;
		        }
				
				vm.reloadDTwithoutNotifications = function(){
					vm.dtWithoutNotifications.length = 0;
					SecFactory.dtWithoutNotifications(vm.sessionComponent.session.id).then(function(data){
						if(data && data.length > 0){
							_.forEach(data, function(item){
								vm.dtWithoutNotifications.push(item);	
							});
						}
					}).catch(function(error){
					})
				};
				
                this.$onInit = function () {
					vm.reloadDTwithoutNotifications();
                    vm.tableDefinition = {
                        id: 'tableTransferNotification',
                        origin: 'reg',
                        objectType: 'NotificationThird',
                        sortName: 'createdDate',
                        sort: [[2, 'asc']],
                        filterCall: getFilterCall(),
                        filterCallAux: getFilterCallAux(),
                        filterCallFunc: getFilterCall,
                        filterCallAuxFunc: getFilterCallAux,
                        columns: [
				            { id: 'notification.globalStatus', width: '20px',  title: $filter('translate')('global.literals.globalStatus'), render: notificationGlobalStatusRender, sortable: false },
				            { id: 'notification.notificationType.'+ vm.languageColumn, width: '150px', column: new DatabaseTranslatedColumn($filter, 'global.literals.notificationTypes', vm.languageColumn) },
				            { id: 'createdDate', width: '120px', column: new DateTimeColumn($filter, 'global.literals.creation_date')},
				            { id: 'notification.extract', width: '120px', title: $filter('translate')('global.literals.extract'), className: 'pre-line'},
   				            { id: 'statuses', title: $filter('translate')('global.literals.state'), render: renderNotificationLastStatus, sortable: false},
				            { id: 'statuses', width: '150px', column: new LastStatusUpdate($filter, 'global.literals.lastStatusUpdate')},
							{ id: 'third', column: new ThirdColumn($filter, 'global.literals.receivers')},
				            { id: 'notification.dossierTransaction', width: '120px', title: $filter('translate')('global.literals.transaction'), render: renderDossierTransaction, sortable: false},
				            { id: 'notification.dossierTransaction', width: '120px', title: $filter('translate')('global.sec.literals.proposalNumber'), render: renderProposal, sortable: false},
				            { id: 'notification.createUser', column: new UserColumn($filter, 'global.literals.user') },
							{ id: 'actions', columnName: 'notification.id', width: '100px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'),
                    			new ActionButton('global.literals.see','$ctrl.showNotificationModal([data])','fa-eye', false), []), sortable: false}
				        ],
                        containerScope: $scope,
                        useTableObjects: true,
                        multipleActions: false,
						callOrigin: 'session',
						callFunctionOnReload:vm.reloadDTwithoutNotifications
                    };
					
					$scope.$on('updateNotificationState', function(event, args){ 
			            if(vm.tableDefinition && vm.tableDefinition.reloadInternalData){
							vm.tableDefinition.reloadInternalData(false, true);
							vm.reloadDTwithoutNotifications();
						}
			        });
                }
        }]
    })
