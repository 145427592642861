angular
    .module('annexaApp')
    .component('annexaSecretaryNotificationTransaction', {
        templateUrl: './components/sec/annexa-secretary-notification-transaction/annexa-secretary-notification-transaction.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'AdminFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, globalModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addTransaction = function () {
            	var modal = angular.copy(globalModals.secNotificationTransactionModal);
                modal.annexaFormly.model = {modal_body:{}};
                modal.annexaFormly.options = { watchAllExpressions: true };
                AnnexaFormlyFactory.showModal('modalNewStreetNumber', modal, vm.saveTransaction, false);
            }
            
            vm.saveTransaction = function(trans){
            	if(trans && trans.annexaFormly && trans.annexaFormly.model && trans.annexaFormly.model.modal_body){
            		var model = {
            			name:trans.annexaFormly.model.modal_body.name,
            			type:trans.annexaFormly.model.modal_body.type,
						profile:trans.annexaFormly.model.modal_body.profile,
						expireType:trans.annexaFormly.model.modal_body.expireType,
						compute:trans.annexaFormly.model.modal_body.compute,
						informationMessage:trans.annexaFormly.model.modal_body.informationMessage,
						responsibleUser:trans.annexaFormly.model.modal_body.responsibleUser
            		}
		        	if(vm.secNotificationId){
		        		model.secNotification = {id:vm.secNotificationId};
            		}
					if(model.expireType && model.expireType.id){
	        			model.expireType = model.expireType.id;  
            		}else{
	        			model.expireType = undefined;
					}
					if(model.compute && model.compute.id){
						model.compute = model.compute.id;
					}else{
						model.compute = undefined;
					}
		        	if(!vm.isNew){
		        		if(model.type && model.type.id){
		        			model.type = {id:model.type.id};  
	            		}
						if(model.profile && model.profile.id){
		        			model.profile = {id:model.profile.id};  
	            		}else{
		        			model.profile = undefined;
						}
						if(model.responsibleUser && model.responsibleUser.id){
		        			model.responsibleUser = {id:model.responsibleUser.id};  
	            		}else{
		        			model.responsibleUser = undefined;
						}
						RestService.insert('./api/sec/sec_notification_transaction', model).then(function(data) {
	        				if(data){
	                            vm.transactions.push(data);	        					
		        			}
	        				trans.close();
                        }).catch(function(error) {
                    		trans.alerts.push({ msg: "Error"});
                        });
		        	}else{
		        		vm.transactions.push(model);
		        		trans.close();
		        	}
            	}
            }

            vm.editTransaction = function (trans, index) {
            	var modal = angular.copy(globalModals.secNotificationTransactionModal);
            	var transUpdate = angular.copy(trans);
            	modal.extra = {
            		trans:trans,
	            	index:index
            	}
				if(transUpdate && transUpdate.expireType){
					transUpdate.expireType = {id:transUpdate.expireType, description:'global.literals.'+transUpdate.expireType};
				}
				if(transUpdate && transUpdate.compute){
					transUpdate.compute = {id:transUpdate.compute, description:((transUpdate.compute)?'global.literals.yes':'global.literals.no')};
				}else{
					transUpdate.compute = {id:false, description:'global.literals.no'};
				}
            	modal.annexaFormly.model = {modal_body:transUpdate};
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	AnnexaFormlyFactory.showModal('modalupdateTransaction', modal, vm.updateTransaction, false);
            }
            
            vm.updateTransaction = function(trans){
            	if(trans.extra.trans){
	            	if(trans && trans.annexaFormly && trans.annexaFormly.model && trans.annexaFormly.model.modal_body){
			        	if(!vm.isNew){
			        		var transAux = angular.copy(trans.extra.trans);
			        		if(trans.annexaFormly.model.modal_body.name){
			        			transAux.name = trans.annexaFormly.model.modal_body.name; 
				        	}else{
			        			transAux.name = undefined;
							}
							if(trans.annexaFormly.model.modal_body.informationMessage){
			        			transAux.informationMessage = trans.annexaFormly.model.modal_body.informationMessage; 
				        	}else{
			        			transAux.informationMessage = undefined;
							}
			        		if(trans.annexaFormly.model.modal_body.type && trans.annexaFormly.model.modal_body.type.id){
			        			transAux.type = {id:trans.annexaFormly.model.modal_body.type.id};  
		            		}else{
			        			transAux.type = undefined;
							}
							if(trans.annexaFormly.model.modal_body.profile && trans.annexaFormly.model.modal_body.profile.id){
			        			transAux.profile = {id:trans.annexaFormly.model.modal_body.profile.id};  
		            		}else{
			        			transAux.profile = undefined;
							}
							if(trans.annexaFormly.model.modal_body.expireType && trans.annexaFormly.model.modal_body.expireType.id){
			        			transAux.expireType = trans.annexaFormly.model.modal_body.expireType.id;  
		            		}else{
			        			transAux.expireType = undefined;
							}
							if(trans.annexaFormly.model.modal_body.compute && trans.annexaFormly.model.modal_body.compute.id){
								transAux.compute = trans.annexaFormly.model.modal_body.compute.id;
							}else{
								transAux.compute = undefined;
							}
							if(trans.annexaFormly.model.modal_body.responsibleUser && trans.annexaFormly.model.modal_body.responsibleUser.id){
								transAux.responsibleUser = {id:trans.annexaFormly.model.modal_body.responsibleUser.id};
							}else{
								transAux.responsibleUser = undefined;
							}
		            		RestService.update('./api/sec/sec_notification_transaction/' + transAux.id, transAux).then(function (data) {
		            			if(trans.annexaFormly.model.modal_body.name){
			        				trans.extra.trans.name = trans.annexaFormly.model.modal_body.name; 
					        	}else{
			        				trans.extra.trans.name = undefined;
								}
				        		if(trans.annexaFormly.model.modal_body.informationMessage){
			        				trans.extra.trans.informationMessage = trans.annexaFormly.model.modal_body.informationMessage; 
					        	}else{
				        			trans.extra.trans.informationMessage = undefined;
								}
				        		if(trans.annexaFormly.model.modal_body.type && trans.annexaFormly.model.modal_body.type.id){
				        			trans.extra.trans.type = trans.annexaFormly.model.modal_body.type;  
			            		}else{
				        			trans.extra.trans.type = undefined;
								}
								if(trans.annexaFormly.model.modal_body.profile && trans.annexaFormly.model.modal_body.profile.id){
				        			trans.extra.trans.profile = trans.annexaFormly.model.modal_body.profile;  
			            		}else{
				        			trans.extra.trans.profile = undefined;
								}
								if(trans.annexaFormly.model.modal_body.expireType && trans.annexaFormly.model.modal_body.expireType.id){
				        			trans.extra.trans.expireType = trans.annexaFormly.model.modal_body.expireType.id;  
			            		}else{
				        			trans.extra.trans.expireType = undefined;
								}
								if(trans.annexaFormly.model.modal_body.compute && trans.annexaFormly.model.modal_body.compute.id){
									trans.extra.trans.compute = trans.annexaFormly.model.modal_body.compute.id;
								}else{
									trans.extra.trans.compute = undefined;
								}
								if(trans.annexaFormly.model.modal_body.responsibleUser && trans.annexaFormly.model.modal_body.responsibleUser.id){
									trans.extra.trans.responsibleUser = trans.annexaFormly.model.modal_body.responsibleUser;
								}else{
									trans.extra.trans.responsibleUser = undefined;
								}
								trans.close();
                            }).catch(function (error) {
                        		trans.alerts.push({ msg: "Error"});
                            });
			        	}else{
			        		if(trans.annexaFormly.model.modal_body.name){
			        			trans.extra.trans.name = trans.annexaFormly.model.modal_body.name; 
				        	}else{
			        			trans.extra.trans.name = undefined;
							}
			        		if(trans.annexaFormly.model.modal_body.informationMessage){
			        			trans.extra.trans.informationMessage = trans.annexaFormly.model.modal_body.informationMessage; 
				        	}else{
			        			trans.extra.trans.informationMessage = undefined;
							}
			        		if(trans.annexaFormly.model.modal_body.type && trans.annexaFormly.model.modal_body.type.id){
			        			trans.extra.trans.type = trans.annexaFormly.model.modal_body.type;  
		            		}else{
			        			trans.extra.trans.type = undefined;
							}
							if(trans.annexaFormly.model.modal_body.profile && trans.annexaFormly.model.modal_body.profile.id){
			        			trans.extra.trans.profile = trans.annexaFormly.model.modal_body.profile;  
		            		}else{
			        			trans.extra.trans.profile = undefined;
							}
							if(trans.annexaFormly.model.modal_body.expireType && trans.annexaFormly.model.modal_body.expireType.id){
			        			trans.extra.trans.expireType = trans.annexaFormly.model.modal_body.expireType.id;  
		            		}else{
			        			trans.extra.trans.expireType = undefined;
							}
							if(trans.annexaFormly.model.modal_body.compute && trans.annexaFormly.model.modal_body.compute.id){
			        			trans.extra.trans.compute = trans.annexaFormly.model.modal_body.compute.id;  
		            		}else{
			        			trans.extra.trans.compute = undefined;
							}
							if(trans.annexaFormly.model.modal_body.responsibleUser && trans.annexaFormly.model.modal_body.responsibleUser.id){
								trans.extra.trans.responsibleUser = trans.annexaFormly.model.modal_body.responsibleUser;
							}else{
								trans.extra.trans.responsibleUser = undefined;
							}
		            		trans.close();
			        	}
	            	}
            	}
            }
            
            vm.deleteTransaction = function (trans, index) {
               	DialogsFactory.confirm('global.sec.literals.deleteSecNotificationTransactionTitle', 'global.sec.literals.deleteSecNotificationTransactionTitleBody').then(function (dataAux) {
           			if(trans){
		        		var transIndex = -1;
		        		if(trans.id){
		        			transIndex = $linq(vm.transactions).indexOf("x => x.id == "+trans.id);
		        		}else{
		        			transIndex = index;
		        		}
		        		if(transIndex > -1){
				        	if(!vm.isNew){
				        		if(trans && trans.id && ( transIndex || transIndex == 0) && vm.transactions && vm.transactions.length >= transIndex){
		                    		RestService.delete('./api/sec/sec_notification_transaction/' + trans.id).then(function (data) {
		                    			vm.transactions.splice(transIndex, 1);
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.transactions.splice(transIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
				if(vm.isNew){
					vm.canEdit = true;
				}else{
					if(vm.canEdit == undefined){
						vm.canEdit = true;
					}
				}
            }
            
        }],
        bindings: {
        	transactions: '=',
            isNew: '=',
            secNotificationId:'=?',
			canEdit: '=?'
        }
    })