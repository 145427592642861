angular
    .module('annexaApp')
    .component('annexaBoxDecreePaginated',{
        templateUrl: './components/sec/annexa-box-decree-paginated/annexa-box-decree-paginated.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'AnnexaPermissionsFactory', '$state', 'SecFactory', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, AnnexaPermissionsFactory, $state, SecFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.redirect = function (id) {
                window.open($state.href('annexa.sec.decrees.edit', { decrees: id }), '_blank');
            }
            
        	this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.box.permissions, 'view_decrees', 'view_decrees', vm.box.isEdit);
	                
					vm.secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec.proposals'").toArray();
	            	
            		if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.sec.literals.decrees';
	                }
				}
            }
        }],
        bindings: {
            box: '='
        }
    })