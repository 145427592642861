angular
    .module('annexaApp')
    .component('annexaBoxInternalCommunication',{
        templateUrl: './components/sec/annexa-box-internal-communication/annexa-box-internal-communication.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'apiAdmin', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, apiAdmin) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.comboYesNo = [
            	{ id: true, description: $filter('translate')('global.literals.yes')},
            	{ id: false, description: $filter('translate')('global.literals.no')}
            ];
            var ttypeAlert = angular.copy(GlobalDataFactory.transactiontypes);
            vm.transactionTypesAlert = $linq(ttypeAlert).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray();
            
            var selectableOptions = [];
            _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
            	var haveProfile = false;
            	if(vm.content && vm.content.internalCommunications) {
            		haveProfile = vm.content.internalCommunications.some(function (resProfile) {
            			  return resProfile.profile.id === value.id;
            			});
            	}
                if(value.expiryDate == null || haveProfile) {
                    selectableOptions.push(value);
                }
            });
            vm.internalCommunicationProfiles = $linq(selectableOptions).orderBy("x => x." + vm.languageColumn, linq.caseInsensitiveComparer).toArray();
          
            vm.proposalInternalCommunicationProfiles = [];
        	
        	if (vm.content && vm.content.internalCommunications) {
        		vm.proposalInternalCommunicationProfiles = $linq(angular.copy(vm.content.internalCommunications)).select("x => x.profile").toArray();
        	}
            
            vm.printInternalCommunicationProfiles = function() {
    			var content = '';
    			
    			if(vm.proposalInternalCommunicationProfiles && vm.proposalInternalCommunicationProfiles.length > 0) {
                    _.forEach(vm.proposalInternalCommunicationProfiles, function(proposalInternalCommunicationProfile){
                    	content += ((content == '')?'':', ') + proposalInternalCommunicationProfile[vm.languageColumn];
                    });
                }
    			return content;
            }

			vm.printAlertTramInternalProfiles = function(){
				if(vm.content && vm.content.alertTramInternalProfiles){
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
			}
			
			vm.printTransactionTypesAlert = function(){
                if (vm.content && vm.content.transactionInternalProfilesAlert) {
                	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+vm.content.transactionInternalProfilesAlert.id);
                	return ((tt)?tt[vm.languageColumn]:'');
                } else {
                	return '';
                }
			}
            
            vm.updateProposalInternalCommunication = function(val, prop) {
            	$rootScope.$broadcast('annexaBoxInternalCommunicationAdd', {val: val, prop: prop, origin: vm.origin});	
            }

            this.$onInit = function () {
            	vm.showAlertInternalProfiles = true;
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.internalCommunication';
                }
            	if($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction 
            			&& $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')) {
            		vm.showAlertInternalProfiles = false;
            	}
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            canEdit: '='
        }
    })