angular
    .module('annexaApp')
    .component('annexaSecretaryNotificationDocuments', {
        templateUrl: './components/sec/annexa-secretary-notification-documents/annexa-secretary-notification-documents.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 'apiAdmin', 'AdminFactory', 
        function (CommonService, Language, RestService, $rootScope, $filter, RestService, globalModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory,apiAdmin, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.addDocument = function () {
            	var modal = angular.copy(globalModals.secNotificationDocumentModal);
                modal.annexaFormly.model = {modal_body:{archiveClassification:{$selected:undefined}}};
                modal.annexaFormly.options = { watchAllExpressions: true };
                AnnexaFormlyFactory.showModal('modalNewStreetNumber', modal, vm.saveDocument, false);
            }
            
            vm.saveDocument = function(document){
            	if(document && document.annexaFormly && document.annexaFormly.model && document.annexaFormly.model.modal_body){
            		var model = {
            			type:document.annexaFormly.model.modal_body.type,
            			name:document.annexaFormly.model.modal_body.name,
						template:document.annexaFormly.model.modal_body.template,
						archiveClassification:((document.annexaFormly.model.modal_body.archiveClassification && document.annexaFormly.model.modal_body.archiveClassification.$selected && document.annexaFormly.model.modal_body.archiveClassification.$selected.id)?document.annexaFormly.model.modal_body.archiveClassification.$selected:undefined),
            		}
		        	if(vm.secNotificationId){
		        		model.secNotification = {id:vm.secNotificationId};
            		}
		        	if(!vm.isNew){
		        		if(model.type && model.type.id){
		        			model.type = {id:model.type.id};  
	            		}
						if(model.template && model.template.id){
		        			model.template = {id:model.template.id};  
	            		}else{
		        			model.template = undefined;
						}
						if(model.archiveClassification && model.archiveClassification.id){
							model.archiveClassification = {id:model.archiveClassification.id};
						}else{
							model.archiveClassification = undefined;
						}
	        			RestService.insert('./api/sec/sec_notification_document', model).then(function(data) {
	        				if(data){
	                            vm.docs.push(JSOG.decode(data));	        					
		        			}
	        				document.close();
                        }).catch(function(error) {
                    		document.alerts.push({ msg: "Error"});
                        });
		        	}else{
		        		vm.docs.push(model);
		        		document.close();
		        	}
            	}
            }

            vm.editDocument = function (document, index) {
            	var modal = angular.copy(globalModals.secNotificationDocumentModal);
            	var documentUpdate = angular.copy(document);
				if(documentUpdate && documentUpdate.archiveClassification && documentUpdate.archiveClassification.id){
					documentUpdate.archiveClassification.title = documentUpdate.archiveClassification[vm.languageColumn];
				}
				documentUpdate.archiveClassification = ((documentUpdate.archiveClassification && documentUpdate.archiveClassification.id)?{$selected:documentUpdate.archiveClassification}:{$selected:undefined});
            	modal.extra = {
            		document:document,
	            	index:index
            	}
            	modal.annexaFormly.model = {modal_body:documentUpdate};
            	modal.annexaFormly.options = { watchAllExpressions: true };
            	AnnexaFormlyFactory.showModal('modalupdateDocument', modal, vm.updateDocument, false);
            }
            
            vm.updateDocument = function(document){
            	if(document.extra.document){
	            	if(document && document.annexaFormly && document.annexaFormly.model && document.annexaFormly.model.modal_body){
			        	if(!vm.isNew){
			        		var documentAux = angular.copy(document.extra.document);
			        		if(document.annexaFormly.model.modal_body.name){
			        			documentAux.name = document.annexaFormly.model.modal_body.name; 
				        	}
			        		if(document.annexaFormly.model.modal_body.type && document.annexaFormly.model.modal_body.type.id){
			        			documentAux.type = {id:document.annexaFormly.model.modal_body.type.id};  
		            		}
							if(document.annexaFormly.model.modal_body.template && document.annexaFormly.model.modal_body.template.id){
			        			documentAux.template = {id:document.annexaFormly.model.modal_body.template.id};  
		            		}else{
			        			documentAux.template = undefined;
							}
							if(document.annexaFormly.model.modal_body.archiveClassification && document.annexaFormly.model.modal_body.archiveClassification.$selected && document.annexaFormly.model.modal_body.archiveClassification.$selected.id){
								documentAux.archiveClassification = {id:document.annexaFormly.model.modal_body.archiveClassification.$selected.id};
							}else{
								documentAux.archiveClassification = undefined;
							}
		            		RestService.update('./api/sec/sec_notification_document/' + documentAux.id, documentAux).then(function (data) {
		            			if(document.annexaFormly.model.modal_body.name){
			        				document.extra.document.name = document.annexaFormly.model.modal_body.name; 
					        	}
				        		if(document.annexaFormly.model.modal_body.type && document.annexaFormly.model.modal_body.type.id){
				        			document.extra.document.type = document.annexaFormly.model.modal_body.type;  
			            		}
								if(document.annexaFormly.model.modal_body.template && document.annexaFormly.model.modal_body.template.id){
				        			document.extra.document.template = document.annexaFormly.model.modal_body.template;  
			            		}else{
				        			document.extra.document.template = undefined;
								}
								if(document.annexaFormly.model.modal_body.archiveClassification && document.annexaFormly.model.modal_body.archiveClassification.$selected && document.annexaFormly.model.modal_body.archiveClassification.$selected.id){
									document.extra.document.archiveClassification = document.annexaFormly.model.modal_body.archiveClassification.$selected;
								}else{
									document.extra.document.archiveClassification = undefined;
								}
								document.close();
                            }).catch(function (error) {
                        		document.alerts.push({ msg: "Error"});
                            });
			        	}else{
			        		if(document.annexaFormly.model.modal_body.name){
			        			document.extra.document.name = document.annexaFormly.model.modal_body.name; 
				        	}
			        		if(document.annexaFormly.model.modal_body.type && document.annexaFormly.model.modal_body.type.id){
			        			document.extra.document.type = document.annexaFormly.model.modal_body.type;  
		            		}
							if(document.annexaFormly.model.modal_body.template && document.annexaFormly.model.modal_body.template.id){
			        			document.extra.document.template = document.annexaFormly.model.modal_body.template;  
		            		}else{
			        			document.extra.document.template = undefined;
							}
							if(document.annexaFormly.model.modal_body.archiveClassification && document.annexaFormly.model.modal_body.archiveClassification.$selected && document.annexaFormly.model.modal_body.archiveClassification.$selected.id){
								document.extra.document.archiveClassification = document.annexaFormly.model.modal_body.archiveClassification.$selected;
							}else{
								document.extra.document.archiveClassification = undefined;
							}
		            		document.close();
			        	}
	            	}
            	}
            }
            
            vm.deleteDocument = function (document, index) {
               	DialogsFactory.confirm('global.sec.literals.deleteSecNotificationDocumentTitle', 'global.sec.literals.deleteSecNotificationDocumentTitleBody').then(function (dataAux) {
           			if(document){
		        		var documentIndex = -1;
		        		if(document.id){
		        			documentIndex = $linq(vm.docs).indexOf("x => x.id == "+document.id);
		        		}else{
		        			documentIndex = index;
		        		}
		        		if(documentIndex > -1){
				        	if(!vm.isNew){
				        		if(document && document.id && ( documentIndex || documentIndex == 0) && vm.docs && vm.docs.length >= documentIndex){
		                    		RestService.delete('./api/sec/sec_notification_document/' + document.id).then(function (data) {
		                    			vm.docs.splice(documentIndex, 1);
	                                }).catch(function (error) {
		                                console.log(error);
		                            });
		                        }
				        	}else{
				        		vm.docs.splice(documentIndex, 1);
				        	}
			        	}
		        	}
                }).catch(function (data) {
	                    //Empty
	            });
            }
            
            this.$onInit = function(){
				if(vm.isNew){
					vm.canEdit = true;
				}else{
					if(vm.canEdit == undefined){
						vm.canEdit = true;
					}
				}
            }
            
        }],
        bindings: {
        	docs: '=',
            isNew: '=',
            secNotificationId:'=?',
			canEdit: '=?'
        }
    })